import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import scs from '../cards/card/card.scss'
import ls from '../list-layout/list/list.scss'
import ss from '../single-event/single-event.scss'
import ts from '../single-event/title/title.scss'
import s from './empty-state.scss'
import {classes} from './empty-state.st.css'
import {EmptyStateProps} from './interfaces'

export const EmptyState = ({singleEventWidget, listLayout, t}: EmptyStateProps) => {
  const {experiments} = useExperiments()
  const updatedEmptyStateEnabled = experiments.enabled(ExperimentNames.UpdatedEmptyState)

  if (updatedEmptyStateEnabled) {
    return (
      <div className={s.emptyState}>
        <h2>
          <Text className={classes.font}>{t('widgetEmptyStateNotification')}</Text>
        </h2>
      </div>
    )
  }

  return (
    <div
      className={classNames(s.container, {
        [ss.fullLayout]: singleEventWidget,
        [ls.listContainer]: !singleEventWidget && listLayout,
        [scs.container]: !singleEventWidget && !listLayout,
        [ts.root]: singleEventWidget,
        [s.listLayout]: !singleEventWidget,
      })}
    >
      {t('widgetEmptyStateNotification')}
    </div>
  )
}
